import { ThemeUIStyleObject } from 'theme-ui';
import Theme from 'Common/themePeazy.json';

const styles: Record<string, ThemeUIStyleObject> = {
  footer: {
    fontFamily: 'Proxima Nova, sans-serif',
    background: Theme.colors.lightGreen,
    p: ['1rem', '1rem', '2rem 2.75rem'],
    flexDirection: ['column', 'column', 'row'],
    justifyContent: 'space-between'
  },
  footerLogos: {
    justifyContent: 'space-between',
    mt: ['0.5rem', '0.5rem', 0],
    mb: ['1.5rem', '1.5rem', 0],
    order: ['0', '0', '4'],
  },
  iconWrapper: {
    width: ['1.75rem', '1.75rem'],
    height: ['1.75rem', '1.75rem'],
    fontSize: ['0.8rem'],
    color: Theme.colors.black,
    background: Theme.colors.darkBlue,
    display: 'flex',
    borderRadius: '100%',
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8
    }
  },
  footerCopy: {
    fontFamily: '"gelica-light", serif',
    fontSize: ['11px', '14px'],
    lineHeight: ['15px', '19px'],
    maxWidth: ['auto', 'auto', '38%'],
    borderTop: ['0.5px solid', '0.5px solid', 'none'],
    paddingTop: ['10px', '10px', '0'],
    marginTop: ['.75rem', '.75rem', '0'],
  },
  footerLinks: {
    flexDirection: ['row', 'row-reverse', 'row'],
    justifyContent: 'space-between',
    mt: ['0.5rem', '0.5rem', 0],
    order: '3',
    flexGrow: 1,
    alignItems: 'center'
  },
  footerLinksWrap: {
    order: ['0', '1', '1'],
    flexWrap: 'wrap',
    justifyContent: ['start', 'center', 'center'],
    pr: [0, 0, '5%'],
    gap: '1rem'
  },
  footerLink: {
    color: Theme.colors.black,
    fontSize: ['11px', '14px'],
    lineHeight: ['25px', '30px'],
    fontWeight: '500',
    order: ['0', '0', '3'],
    flexShrink: 0,
    p: ['0 9px 0 0', '0 9px 0 0', '0 10px'],
    textDecoration: 'none',
    '&:hover': {
      color: Theme.colors.darkBlue
    },
    '@media (min-width:1300px)': {
      flexShrink: '0'
    }
  },
  cc: {
    color: Theme.colors.black,
    fontSize: ['11px', '14px'],
    lineHeight: ['15px', '19px'],
    order: ['1', '0', '0'],
    px: ['0', '0', '5%'],
    flexShrink: [1, 1, 0]
  },
  hideOnMobile: {
    display: ['none', 'inline', 'inline']
  }
};

export default styles;

import React from 'react';
import style from './style';
import { Flex, Link, Text, ThemeUIStyleObject } from 'theme-ui';
import { FaLinkedinIn } from 'react-icons/fa';
import HeaderLogo from '../HeaderLogo/HeaderLogo';

type FooterProps = {
  style?: ThemeUIStyleObject;
}

const Footer: React.FC<FooterProps> = ({
  style: pStyle,
}) => {
  return (
    <Flex
      data-testid="footer"
      sx={{
        ...pStyle,
        ...style.footer,
      }}
    >
      <Flex sx={style.footerLogos}>
        <Flex sx={{ display: ['flex', 'flex', 'none'] }}>
          <HeaderLogo />
        </Flex>
        <Flex
          sx={{
            flexDirection: 'row',
            flexGrow: 1,
            justifyContent: 'flex-end',
            alignItems: ['flex-start', 'flex-start', 'center'],
          }}
        >
          <Link href="https://www.linkedin.com/company/bookpeazy/">
            <Flex sx={style.iconWrapper}>
              <FaLinkedinIn />
            </Flex>
          </Link>
          {/* <Flex sx={{ ...style.iconWrapper, ml: '10px' }}>
            <FaInstagram />
          </Flex> */}
        </Flex>
      </Flex>
      <Flex
        sx={style.footerCopy}
      >
        <Text
          variant='body2'
          sx={{
            fontWeight: ['600', '600', '400'],
          }}
        >
          Peazy connects busy people with the
          {' '}professional services they need at home or in the office;
          {' '}no hassle, no commute, no sweat. It’s your time.
        </Text>
      </Flex>
      <Flex sx={style.footerLinks}>
        <Flex sx={style.footerLinksWrap}>
          <a
            sx={style.footerLink}
            href="https://www.bookpeazy.com/peazy-terms-of-use"
            target="_blank"
            rel="noreferrer"
          >
            Terms<span sx={style.hideOnMobile}>&nbsp;of Service</span>
          </a>
          <a
            sx={style.footerLink}
            href="/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </Flex>
        <Text sx={style.cc}>
          © {(new Date).getUTCFullYear()} Peazy, all rights reserved
        </Text>
      </Flex>
    </Flex>);
};

export default Footer;

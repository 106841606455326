import React, { useMemo, useState } from 'react';
import {
  LocationType,
  V2Gateway_GetLocationsByUidQueryHookResult,
} from 'Generated/graphql';
import {
  getLocationList, getSubscribedLocationSelectInactive,
} from './functions';
import LocationSelectListItem, {
  LocationSelectListItemProps,
  LocationWithLatLng,
} from 'Common/components/LocationSelectListItem';
import { Box, Flex, Text } from 'theme-ui';
import { cardButtonBase } from 'Common/theme';
import styles from './style';
import ChevronDownLarge from 'Images/svg/chevron-down-large.turbopack-temp-svg';
import {
  UseLocationSubscriptions,
} from 'Common/hooks/useLocationSubscriptions';
import { trimFormattedAddress } from 'Common/functions/Location';
import { UseIndividualLocations } from 'Common/hooks/useIndividualLocations';
import LocationForm from '../LocationForm';
import { LocationModFieldKey } from '../LocationForm/functions';

export type SubscribedLocationSelectLocations = NonNullable<
  V2Gateway_GetLocationsByUidQueryHookResult['data']
>['getLocationsByUid']

export type SubscribedLocationSelectProps<
  T extends LocationWithLatLng = LocationWithLatLng
> = {
  locationListItemProps?: Partial<
    Omit<
      LocationSelectListItemProps<T>, 'location' | 'onClick'
    >
  >;
  value?: string;
  onClear?: () => void;
  onSelect?: (l: T) => void;
  minimal?: boolean;
  individualLocations: Omit<UseIndividualLocations[number], 'serviceAreas'>[] | undefined;
  sharedLocations: UseLocationSubscriptions[number]['location'][];
  onUpdate?: () => void;
  isAnonymous?: boolean | null;
}

const SubscribedLocationSelect: React.FC<
  SubscribedLocationSelectProps
> = React.memo(({
  locationListItemProps = {},
  value,
  onClear,
  onSelect,
  minimal = false,
  individualLocations,
  sharedLocations,
  onUpdate,
  isAnonymous = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const individualIndeterminateLocation = useMemo(
    () => individualLocations?.find(
      l => l.type === LocationType.IndividualIndeterminate,
    ),
    [JSON.stringify(individualLocations)],
  );
  const onMouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const childNode = e.relatedTarget as Node;
    const childNodeIsNode = typeof childNode.childNodes !== 'undefined';
    const isChildOfThis = (childNodeIsNode &&
      e.currentTarget.contains(childNode));
    if (childNode && isChildOfThis || e.relatedTarget === window) {
      // Ignore: Mouse is still within the parent, over a child element
      return;
    }

    setIsOpen(false);
  };

  const dedupedIndividualLocations = useMemo(() => {
    if (individualLocations) {
      return individualLocations.filter(
        il => !sharedLocations.some(sl => sl.placeId === il.placeId)
      );
    }
  }, [individualLocations, sharedLocations]);

  const MinimalLocationListM = useMemo(() => {
    const currentLocation = [
      ...sharedLocations,
      ...(individualLocations || []),
    ].find(l => l.uid === value);

    const locationsListContent = (
      <Box
        sx={styles.floatingContainer}
        onMouseLeave={onMouseLeave}
      >
        <Box sx={styles.accountInfoInnerContainer}>
          {sharedLocations.map(
            l => l.type !== LocationType.Individual &&
                l.type !== LocationType.IndividualIndeterminate &&
                l.type !== LocationType.IndividualWork && (
              <Flex
                sx={styles.accountItemsV2}
                onClick={() => {
                  onSelect && onSelect(l);
                  setIsOpen(false);
                }} key={l.uid}>
                {trimFormattedAddress(l.formattedAddress)}
              </Flex>
            )
          )}
          {dedupedIndividualLocations?.map((il, ix) => (
            <Flex key={ix} sx={styles.accountItemsV2} onClick={() => {
              onSelect && onSelect(il);

              setIsOpen((curr) => !curr);
            }}>
              {trimFormattedAddress(il.formattedAddress)}
            </Flex>
          ))}
        </Box>
      </Box>
    );

    const editIndividualIndeterminateLocationContent = (
      <Box
        data-testid="subscribedLocationSelect_locationFormWrap"
        sx={styles.floatingContainer}
        onMouseLeave={onMouseLeave}
      >
        <Box sx={{ padding: '1rem', }}>
          <LocationForm
            hideFields={[
              LocationModFieldKey.Type,
              LocationModFieldKey.Name,
              LocationModFieldKey.PhoneNumber,
            ]}
            existingLocation={currentLocation || individualIndeterminateLocation}
            overrideFields={individualIndeterminateLocation ? undefined : currentLocation ? {
              name: currentLocation.name,
              type: currentLocation.type,
              phoneNumber: currentLocation.phoneNumber,
            } : {
              name: 'Temporary Location',
              type: LocationType.IndividualIndeterminate,
              phoneNumber: '0000000000',
            }}
            onUpdate={() => {
              onUpdate && onUpdate();
            }}
            submitButtonCopy={isAnonymous ? 'Book Services In My Area' : undefined}
          />
        </Box>
      </Box>
    );

    const isShowingLocationForm = isAnonymous ||
        (dedupedIndividualLocations?.length === 0 &&
          sharedLocations.length === 0);

    const content = isShowingLocationForm ?
      editIndividualIndeterminateLocationContent :
      locationsListContent;

    return (
      <Flex
        sx={{
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '100%'
        }}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <Flex sx={styles.accountInfoWrapperV2}
        //onMouseLeave={() => setIsOpen(false)}
        >
          <Flex
            sx={styles.currentLocationTextContainer}
          >
            <Text
              variant="navigationLocationText"
              data-cy="subscribedLocationSelect_selectedAddress"
            >
              {currentLocation ? trimFormattedAddress(
                currentLocation.formattedAddress,
              ) : 'Enter Your Address'}&nbsp;
            </Text>
            <Box
              data-testid="subscribedLocationSelect_chevronDown"
              as="span"
              sx={{
                display: 'block',
                maxWidth: [8, 8, 14],
              }}
            >
              <ChevronDownLarge style={{ overflow: 'visible' }} />
            </Box>
          </Flex>
          {isOpen && content}
        </Flex>
      </Flex>
    );
  }, [
    JSON.stringify(sharedLocations),
    JSON.stringify(dedupedIndividualLocations),
    JSON.stringify(individualLocations),
    value,
    isOpen,
  ]);

  if (sharedLocations.length === 0 && !individualLocations) {
    return <></>;
  }

  if (minimal) {
    return MinimalLocationListM;
  }

  return value ? (
    <>
      {getSubscribedLocationSelectInactive(sharedLocations)(value)(
        () => onClear && onClear()
      )}
    </>
  ) : (
    <Box>
      {getLocationList(
        sharedLocations,
        {
          ...locationListItemProps,
          onClick: l => {
            onSelect && onSelect(l);
          },
          styles: cardButtonBase,
        },
      )}
      {dedupedIndividualLocations?.map((il, ix) => (
        <LocationSelectListItem
          testId={`subscribedLocationSelect_locationSelectListItem_${ix}`}
          key={il.uid}
          location={il}
          onClick={l => {
            onSelect && onSelect(l);
          }}
          {...locationListItemProps}
        />
      ))}
    </Box>
  );
});

export default SubscribedLocationSelect;

import React from 'react';
import {
  V2Gateway_QueryLocationsLazyQueryHookResult,
} from 'Generated/graphql';
import { Box, Button, Flex, Heading } from 'theme-ui';
import LocationSelectListItem, {
  LocationSelectListItemProps,
} from 'Common/components/LocationSelectListItem';
import { EditOutlined } from '@ant-design/icons';
import {
  UseLocationSubscriptions,
} from 'Common/hooks/useLocationSubscriptions';
import { trimFormattedAddress } from 'Common/functions/Location';

type Locations = NonNullable<
  V2Gateway_QueryLocationsLazyQueryHookResult[1]['data']
>['queryLocations']['locations']

export const getLocationList =
  (
    locations: Pick<Locations[number], 'name' | 'formattedAddress' | 'uid' | 'latitude' | 'longitude'>[],
    locationListItemProps?: Partial<LocationSelectListItemProps>,
  ): React.ReactNode[] | React.ReactNode | null => locations.map(l => (
    <Box key={l.uid}>
      <LocationSelectListItem
        location={l}
        {...locationListItemProps}
      />
    </Box>
  ));

export const getSubscribedLocationSelectInactive = (
  subscribedLocations: UseLocationSubscriptions[number]['location'][],
) => (
  selectedLocationUid: string,
) => (
  onClickEdit: () => void,
): React.ReactNode => {
  const loc = subscribedLocations.find(
    l => l.uid === selectedLocationUid
  );

  return loc ? (
    <Flex
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Heading as="h4">
        {trimFormattedAddress(loc.formattedAddress)}
      </Heading>
      <Button
        type="button"
        variant="buttons.secondary"
        onClick={() => onClickEdit && onClickEdit()}
      >
        <EditOutlined />
      </Button>
    </Flex >
  ) : <></>;
};

import { AppLocalStorageKeys } from 'Common/functions/LocalStorage';
import useLocalStorage from './useLocalStorage';
import { useMemo, useState } from 'react';
import { GetSelectedLocationForUser } from 'Common/functions/Location';
import { SearchParam } from 'Common/functions/Search';
import { useRouter } from 'next/router';
import { useApolloClient } from '@apollo/client';

const useSelectedLocationUid = (ownerUid: string | undefined): [
  string | undefined,
  (s: string) => void,
] => {
  const { query } = useRouter();
  const searchDefaultLocationUid =
    query[SearchParam.BROWSE_PAGE_DEFAULT_LOCATION_UID] as string | undefined;
  const apolloClient = useApolloClient();

  const [cachedLocationUids, setCachedLocationUids] =
    useLocalStorage<{ [x: string]: string }>(
      AppLocalStorageKeys.APPOINTMENT_REQUEST_WIDGET_LOCATION_UID_MAP,
      {},
    );
  const defaultLocationUid = searchDefaultLocationUid || (ownerUid ?
    cachedLocationUids[ownerUid] :
    undefined);

  const [selectedLocationUid, setSelectedLocationUid] =
    useState<undefined | string>(defaultLocationUid);

  useMemo(() => {
    if (ownerUid) {
      const cachedLocationUid = cachedLocationUids[ownerUid];
      if (cachedLocationUid || defaultLocationUid) {
        return;
      }

      GetSelectedLocationForUser(
        apolloClient,
        undefined,
        ownerUid,
        undefined,
      ).then(l => {
        if (l) {
          setSelectedLocationUid(l.uid);
          setCachedLocationUids({
            [ownerUid]: l.uid
          });
        }
      });
    }
  }, [ownerUid, cachedLocationUids]);

  return [
    selectedLocationUid,
    (locationUid: string) => {
      if (ownerUid) {
        setCachedLocationUids({
          [ownerUid]: locationUid
        });

        setSelectedLocationUid(locationUid);
      }
    }
  ];
};

export default useSelectedLocationUid;

import { formatServiceTypeSingular, ServiceType } from '@appbookpeazy/common-js';
import { Image, Text, Flex, Box } from 'theme-ui';
import Theme from 'Common/themePeazy.json';
import Link from 'next/link';
import { AppRoutePath } from 'Pages/routes';
import { logEvent, getAnalytics } from 'firebase/analytics';

type ServiceTypeListItemProps = {
  serviceType: ServiceType;
  onSelect?: (t: ServiceType) => void;
  value?: ServiceType[];
};

export const ServiceTypePrettyNames: Partial<Record<ServiceType, string>> = {
  [ServiceType.FITNESS_YOGA]: 'Fitness/Yoga Classes',
  [ServiceType.WELLNESS]: 'Wellness Coach',
  [ServiceType.MASSAGE]: 'Massage Therapy',
  [ServiceType.OIL_CHANGE]: 'Oil Change & Tire Swap',
};

export const isPhaseOneTypes = (arr: ServiceType): boolean => {
  const phaseOneType = [
    ServiceType.HAIRCUT,
    ServiceType.MASSAGE,
    ServiceType.ESTHETICIAN,
    ServiceType.DRY_CLEAN,
    ServiceType.DIETITIAN,
    ServiceType.PHYSIOTHERAPY,
  ];
  return phaseOneType.includes(arr);
};

const phaseOneImages = {
  Haircut: 'haircut/haircut_hdgyzd.jpg',
  Massage: 'massage/massage_nm5kjc.webp',
  DryCleaning: 'dry_cleaning/drycleaning_ropevr.webp',
  OilChange: 'phase2/oil_change_amgmmj',
  Esthetician: 'esthetician/nails_lglfvr.webp'
};

const serviceImages = {
  ...phaseOneImages,
  CarWash: 'car_wash/index.jpg',
  Yoga: 'yoga/index.jpg',
  FitnessClass: 'fitness_class/index.jpg',
  Laundry: 'laundry/index.jpg',
  PersonalShopping: 'personal_shopping/index.jpg',
  Pet: 'dog_walking/index.jpg',
  Dietitian: 'dietitian/peazy_dietitan_services_1_gghtnj.jpg',
  Wellness: 'wellness/index.jpg',
  General: 'general/index.jpg',
  ChefServices: 'chef_services/index.jpg',
  WineTasting: 'wine_tasting/index.jpg',
  NailTech: 'nail_tech/index.jpg',
  Ergonomics: 'ergonomics/index.jpg',
  Physiotherapy: 'physiotherapy/index.webp',
};

const getCloudinaryServiceImageURL = (service: string) => {
  return `https://res.cloudinary.com/mimobl/f_auto,dpr_auto,w_auto:50:500/mimobl-app/services/${service}`;
};

export const getServiceTypeImage = (service: ServiceType) => {
  let imageName;
  switch (service) {
  case ServiceType.HAIRCUT:
    imageName = serviceImages.Haircut;
    break;
  case ServiceType.OIL_CHANGE:
    imageName = serviceImages.OilChange;
    break;
  case ServiceType.MASSAGE:
    imageName = serviceImages.Massage;
    break;
  case ServiceType.CAR_WASH:
    imageName = serviceImages.CarWash;
    break;
  case ServiceType.DRY_CLEAN:
    imageName = serviceImages.DryCleaning;
    break;
  case ServiceType.LAUNDRY:
    imageName = serviceImages.Laundry;
    break;
  case ServiceType.FITNESS_YOGA:
    imageName = serviceImages.FitnessClass;
    break;
  case ServiceType.DOG_WALKING:
    imageName = serviceImages.Pet;
    break;
  case ServiceType.ESTHETICIAN:
    imageName = serviceImages.Esthetician;
    break;
  case ServiceType.WELLNESS:
    imageName = serviceImages.Wellness;
    break;
  case ServiceType.DIETITIAN:
    imageName = serviceImages.Dietitian;
    break;
  case ServiceType.PERSONAL_SHOPPING:
    imageName = serviceImages.PersonalShopping;
    break;
  case ServiceType.CHEF_SERVICES:
    imageName = serviceImages.ChefServices;
    break;
  case ServiceType.WINE_TASTING:
    imageName = serviceImages.WineTasting;
    break;
  case ServiceType.NAIL_TECH:
    imageName = serviceImages.NailTech;
    break;
  case ServiceType.ERGONOMICS:
    imageName = serviceImages.Ergonomics;
    break;
  case ServiceType.PHYSIOTHERAPY:
    imageName = serviceImages.Physiotherapy;
    break;
  default:
    imageName = serviceImages.Dietitian;
  }
  return getCloudinaryServiceImageURL(imageName);
};

const ServiceTypeListItem: React.FC<ServiceTypeListItemProps> = ({
  serviceType,
  onSelect
}) => {
  const src = getServiceTypeImage(serviceType);

  const content = (
    <Flex
      sx={{
        flexDirection: 'column',
        borderRadius: '4px',
        overflow: 'hidden',
        cursor: 'pointer'
      }}
    >
      <Flex
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          background: Theme.colors.lightBlue,
        }}
      >
        <Image
          src={src}
          alt={
            ServiceTypePrettyNames[serviceType] ||
            formatServiceTypeSingular(serviceType)
          }
          sx={{
            objectFit: ['cover', 'unset'],
            height: ['162px', '200px'],
            minWidth: '100%',
          }}
        />
      </Flex>
      <Text variant="concierge.marketPlaceTitle">
        {ServiceTypePrettyNames[serviceType] ||
          formatServiceTypeSingular(serviceType)}
      </Text>
    </Flex>
  );

  const pathname = AppRoutePath.BROWSE_SERVICE_PROVIDERS
    .replace('[serviceType]', serviceType);

  return (
    <Link
      href={pathname}
      style={{
        textDecoration: 'none',
      }}
    >
      <Box
        data-testid={`serviceTypeSelect_${serviceType}`}
        data-cy={`serviceTypeSelect_${serviceType}`}
        onClick={e => {
          logEvent(
            getAnalytics(),
            'funnel_1_self_serve_flow_sp_select_page',
            {
              serviceType
            }
          );

          if (onSelect) {
            e.stopPropagation();
            e.preventDefault();

            onSelect(serviceType);
          }
        }}
      >
        {content}
      </Box>
    </Link>
  );
};

export default ServiceTypeListItem;


import { ServiceType } from '@appbookpeazy/common-js';
import Footer from 'Common/components/Footer';
import Navigation, {
  NavigationContentType,
} from 'Common/components/Navigation';
import ServiceTypeSelect from 'Common/components/ServiceTypeSelect';
import SubscribedLocationSelect from
  'Common/components/SubscribedLocationSelect';
import IdentityContext from 'Common/context/IdentityContext';
import { AccountType } from 'Common/functions/AccountType';
import { GetSelectedLocationForUser } from 'Common/functions/Location';
import { SearchParam } from 'Common/functions/Search';
import useSubscribedLocations from 'Common/hooks/useSubscribedLocations';
import {
  V2Gateway_QueryLocationsQueryResult,
} from 'Generated/graphql';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { AppRoutePath } from 'Pages/routes';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Flex } from 'theme-ui';
import { withUserTokenSSR } from 'next-firebase-auth';
import useIndividualLocations from 'Common/hooks/useIndividualLocations';
import { useApolloClient } from '@apollo/client';
import signInAnonymously from 'Common/functions/signInAnonymously';
import { getAuth } from 'firebase/auth';
import {
  dedupeIndividualLocationsAgainstSharedLocations
} from 'Common/functions/Location';
import useSelectedLocationUid from 'Common/hooks/useSelectedLocationUid';
import initFirebaseAuth from 'Common/functions/initFirebaseAuth';
import PromptCreateHomeLocation from 'Common/components/PromptCreateHomeLocation';

type locations = NonNullable<
  V2Gateway_QueryLocationsQueryResult['data']
>['queryLocations']['locations'];

type location = locations[number];

const omitTypes = [
  ServiceType.CHIROPRACTIC,
  ServiceType.CLEANING,
  ServiceType.DENTAL,
  ServiceType.MAINTENANCE,
  ServiceType.OPTOMETRIST,
  ServiceType.PLUMBING,
  ServiceType.CHEF,
  ServiceType.VETERINARIAN,
  ServiceType.MEDITATION,
  ServiceType.ASL_CLASS,
  ServiceType.FINANCIAL,
  ServiceType.TAX_PLANNING,
  ServiceType.SKATE_SHARPENING,
  ServiceType.NAIL_TECH,
  ServiceType.COOKING_CLASS,
  ServiceType.YOGA_CLASSES,
  ServiceType.LAUNDRY,
  ServiceType.YOGA,
  ServiceType.FITNESS_CLASS,
  ServiceType.PET_SERVICES,
  ServiceType.CAR_WASH,
  ServiceType.OIL_CHANGE,
  ServiceType.WINE_TASTING,
  ServiceType.CHEF_SERVICES,
  ServiceType.PERSONAL_SHOPPING,
  ServiceType.FITNESS_YOGA,
  ServiceType.ERGONOMICS,
  ServiceType.WELLNESS,
];

type BrowseServiceTypesProps = {
  ownerUid: string;
}

const BrowseServiceTypes: NextPage<BrowseServiceTypesProps> = ({
  ownerUid: pOwnerUid,
}) => {
  const router = useRouter();
  const {
    accountType,
    ownerUid: ctxOwnerUid,
    getIdentityQuery,
    getUserQuery
  } = useContext(IdentityContext);
  const ownerUid = ctxOwnerUid || pOwnerUid;
  const [locationUid, setLocationUid] = useSelectedLocationUid(ownerUid);
  const [allIndividualLocations, refetchIndividualLocations] =
    useIndividualLocations(ownerUid);
  const [sharedLocations, refetchSharedLocations] =
    useSubscribedLocations(ownerUid, 'network-only');
  const [returnToServiceType, setReturnToServiceType] =
    useState<ServiceType | undefined>(undefined);
  const [selectedLocation, setSelectedLocation] =
    useState<Omit<location, 'serviceAreas'> | undefined>();
  const [isPromptCreateLocationModelOpen, setIsPromptCreateLocationModelOpen] =
    useState(false);
  const client = useApolloClient();
  const {
    isAnonymous,
  } = useContext(IdentityContext);

  const individualLocations = useMemo(() => {
    if (allIndividualLocations && sharedLocations) {
      const indivLocs = dedupeIndividualLocationsAgainstSharedLocations(
        allIndividualLocations,
        sharedLocations,
      );

      return indivLocs;
    }
  }, [allIndividualLocations, sharedLocations]);

  const navigateToServiceTypePage = (serviceType: ServiceType) => {
    const pathname = AppRoutePath.BROWSE_SERVICE_PROVIDERS
      .replace('[serviceType]', serviceType);
    router.push({
      pathname,
      query: router.query,
    });
  };

  const onSelectServiceTypeCallback = (serviceType?: ServiceType) => {
    // If we are logged in and have a location selected for the appointment
    if (locationUid && ownerUid) {
      if (serviceType) {
        navigateToServiceTypePage(serviceType);
        return;
      }
    } else if (serviceType) {
      setReturnToServiceType(serviceType);
      setIsPromptCreateLocationModelOpen(true);
    } else {
      setIsPromptCreateLocationModelOpen(true);
    }
  };

  // TODO: do this serverside
  useEffect(() => {
    if (accountType === AccountType.LocationOwner) {
      router.push({
        pathname: AppRoutePath.BUSINESS_BROWSE_SERVICE_TYPES,
      });
    } else if (accountType === AccountType.ServiceProvider) {
      router.push({
        pathname: AppRoutePath.DASHBOARD,
      });
    }
  }, [accountType]);

  useEffect(() => {
    if (ownerUid &&
      (!selectedLocation || selectedLocation.uid !== locationUid)) {
      const id = window.setInterval(() => {
        GetSelectedLocationForUser(
          client,
          undefined,
          ownerUid,
          locationUid,
        ).then(l => {
          setSelectedLocation(l);
          refetchSharedLocations();
          if (l) {
            setLocationUid(l.uid);
          }
        });
      }, 300);

      return () => clearInterval(id);
    }
  }, [ownerUid, locationUid, selectedLocation?.uid]);

  useEffect(() => {
    if (ownerUid) {
      if (individualLocations?.length) {
        const selIndiv = individualLocations.find(i => i.uid === locationUid);
        if (selIndiv) {
          setSelectedLocation(selIndiv);
          setLocationUid(selIndiv.uid);
          return;
        }
      }

      if (sharedLocations) {
        const l = sharedLocations.find(l => l.uid === locationUid);
        if (l) {
          setSelectedLocation(l);
          setLocationUid(l.uid);
        } else if (sharedLocations.length) {
          const l = sharedLocations[0];
          setSelectedLocation(l);
          setLocationUid(l.uid);
        }
      }
    }
  }, [locationUid, sharedLocations, ownerUid, individualLocations]);

  const sharedLocationSelect = useMemo(() => sharedLocations ? (
    <SubscribedLocationSelect
      minimal
      value={locationUid}
      sharedLocations={sharedLocations}
      individualLocations={individualLocations}
      onSelect={l => {
        if (ownerUid) {
          setLocationUid(l.uid);
        }
        refetchIndividualLocations();
      }}
      onClear={() => {
        setLocationUid('');
      }}
      onUpdate={() => refetchIndividualLocations()}
      isAnonymous={isAnonymous}
    />
  ) : <></>, [JSON.stringify(sharedLocations),
    locationUid,
    JSON.stringify(individualLocations)]);

  useEffect(() => {
    if (selectedLocation) {
      router.replace({
        pathname: router.pathname,
        query: {
          ...router.query,
          [SearchParam.BROWSE_SERVICE_PROVIDERS_LAT]: selectedLocation.latitude,
          [SearchParam.BROWSE_SERVICE_PROVIDERS_LNG]: selectedLocation.longitude,
        },
      });
    }
  }, [selectedLocation?.uid]);

  return (
    <>
      <Flex sx={{
        flexDirection: 'column',
        minHeight: '100%',
        background: 'offWhite'
      }}>
        <Navigation
          navContentType={isAnonymous ?
            NavigationContentType.ANONYMOUS_CUSTOMER :
            NavigationContentType.CUSTOMER}
          hideBackButton
          signupButtonLink={AppRoutePath.CUSTOMER_SIGNUP}
        >
          <Flex
            sx={{
              justifyContent: 'space-evenly',
              alignItems: 'center',
            }}
          >
            {ownerUid && sharedLocationSelect}
          </Flex>
        </Navigation>
        <Flex
          sx={{
            flexDirection: 'column',
            width: '100%',
            flexGrow: 1
          }}
        >
          <ServiceTypeSelect
            onSelect={([serviceType]) => {
              if (ownerUid) {
                onSelectServiceTypeCallback(serviceType);
              } else {
                signInAnonymously(getAuth()).then(
                  () => Promise.all([
                    getIdentityQuery.refetch(),
                    getUserQuery.refetch()
                  ]).then(() => onSelectServiceTypeCallback(serviceType))
                );
              }
            }}
            filter={t => {
              const fa = t.filter(s => !omitTypes.includes(s));
              return fa;
            }}
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            presentation="card"
          />
        </Flex>
        <Footer />
      </Flex>
      <PromptCreateHomeLocation
        isOpen={isPromptCreateLocationModelOpen}
        onClose={() => setIsPromptCreateLocationModelOpen(false)}
        onFlowComplete={(loc) => {
          setLocationUid(loc.uid);
          refetchIndividualLocations();
          refetchSharedLocations();
          setIsPromptCreateLocationModelOpen(false);

          if (returnToServiceType) {
            navigateToServiceTypePage(returnToServiceType);
          }
        }}
      />
    </>
  );
};

export const getServerSideProps = () => {
  initFirebaseAuth();

  return withUserTokenSSR()(
    async (context) => {
      const ownerUid = context.user?.id;

      if (ownerUid) {
        return {
          props: {
            ownerUid,
          }
        };
      }

      return {
        props: {
          ownerUid: '',
        }
      };
    }
  );
};

export default BrowseServiceTypes;


import {
  LocationType,
  ReferenceType,
  useV2Gateway_QueryLocationsLazyQuery,
  V2Gateway_QueryLocationsQueryHookResult,
} from 'Generated/graphql';
import { useEffect, useState } from 'react';

export type UseIndividualLocations = NonNullable<
  V2Gateway_QueryLocationsQueryHookResult['data']
>['queryLocations']['locations']

const useIndividualLocations = (ownerUid?: string): [
  UseIndividualLocations | undefined,
  () => void,
  boolean
] => {
  const [queryLocations, queryLocationsQuery] =
    useV2Gateway_QueryLocationsLazyQuery({
      fetchPolicy: 'network-only',
    });
  const [locations, setLocations] = useState<UseIndividualLocations>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (ownerUid) {
      setIsLoading(true);
      queryLocations({
        variables: {
          query: {
            references: [{
              referenceIds: [ownerUid],
              referenceType: ReferenceType.Owner,
            }],
            types: [
              LocationType.Individual,
              LocationType.IndividualWork,
              LocationType.IndividualIndeterminate,
            ],
          },
        },
        fetchPolicy: 'network-only',
      });
    }
  }, [ownerUid]);

  useEffect(() => {
    const { data } = queryLocationsQuery;
    if (data) {
      if (data.queryLocations.locations.length) {
        const l = [...data.queryLocations.locations].sort(
          (a, b) => a.type === LocationType.Individual &&
            b.type !== LocationType.Individual ? -1 : 1
        );
        setLocations(l);
      } else {
        setLocations([]);
      }
      setIsLoading(false);
    }
  }, [queryLocationsQuery.data]);

  return [
    locations,
    () => queryLocationsQuery.refetch && queryLocationsQuery.refetch(),
    isLoading,
  ];
};

export default useIndividualLocations;


import { Modal } from 'antd';
import { getCookie, AppCookie, setCookie } from 'Common/functions/Cookie';
import {
  LocationType,
  useV2Gateway_GetLocationLazyQuery,
  V2Gateway_QueryLocationsQueryHookResult,
} from 'Generated/graphql';
import { Heading, Flex } from 'theme-ui';
import LocationForm from '../LocationForm';
import { LocationModFieldKey } from '../LocationForm/functions';

type locations = NonNullable<
  V2Gateway_QueryLocationsQueryHookResult['data']
>['queryLocations']['locations']

type PromptCreateHomeLocation = {
  onFlowComplete?: (location: Omit<locations[number], 'serviceAreas'>) => void;
  preventClose?: boolean;
  isOpen: boolean;
  onClose: () => void;
}

const isSSR = typeof window === 'undefined';

const PromptCreateHomeLocation: React.FC<
  PromptCreateHomeLocation
> = ({
  onFlowComplete,
  preventClose = false,
  isOpen,
  onClose,
}) => {
  const timesClosedStr = isSSR ?
    null :
    getCookie(AppCookie.ClosedJoinSharedLocationPrompt);
  const timesClosed = timesClosedStr ? parseInt(timesClosedStr) : 0;
  const [getLocation] = useV2Gateway_GetLocationLazyQuery();

  const handleLocationCreated = (locationUid: string) => {
    getLocation({
      variables: {
        locationUid,
      }
    }).then(async ({ data }) => {
      if (data) {
        const loc = data.getLocation;
        if (onFlowComplete) {
          onFlowComplete(loc);
        }
      }
    });
  };

  return (
    <Modal
      open={isOpen}
      title=""
      footer={<></>}
      closable={!preventClose}
      onCancel={() => {
        if (preventClose) {
          return;
        }

        const tc = timesClosed ? timesClosed + 1 : 1;
        setCookie(
          AppCookie.ClosedJoinSharedLocationPrompt,
          tc.toString(),
        );

        onClose();
      }}
    >
      <>
        <Flex>
          <Flex
            sx={{
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Flex
              sx={{
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <>
                <Heading variant='h1'>
                  Add Your Address:
                </Heading>
                <LocationForm
                  hideFields={[
                    LocationModFieldKey.Type,
                    LocationModFieldKey.Name,
                    LocationModFieldKey.PhoneNumber,
                  ]}
                  overrideFields={{
                    name: 'Temporary Location',
                    type: LocationType.Individual,
                    phoneNumber: '0000000000',
                  }}
                  onCreateLocations={uids => {
                    if (uids.length) {
                      handleLocationCreated(uids[0]);
                    }
                  }}
                  submitButtonCopy='Book Services In My Area'
                />
              </>
            </Flex>
          </Flex>
        </Flex>
      </>
    </Modal>
  );
};

export default PromptCreateHomeLocation;


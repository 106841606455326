import { WatchQueryFetchPolicy } from '@apollo/client';
import {
  LocationType,
  ReferenceType,
  V1Beta3_ReferenceMatchType,
} from 'Generated/graphql';
import { useMemo } from 'react';
import useLocationSubscriptions, {
  UseLocationSubscriptions,
} from './useLocationSubscriptions';

export type SubscribedLocations =
  UseLocationSubscriptions[number]['location'][] | undefined

const useSubscribedLocations = (
  ownerUid: string | undefined,
  fetchPolicy?: WatchQueryFetchPolicy,
): [
   SubscribedLocations,
  () => void,
] => {
  const [sharedLocationsSubscriptions, refetchSharedLocationSubscriptions] =
    useLocationSubscriptions(ownerUid ? [{
      ids: [ownerUid],
      type: ReferenceType.Owner,
      match_type: V1Beta3_ReferenceMatchType.Any,
    }] : undefined, fetchPolicy);

  const sharedLocations = useMemo(
    () => sharedLocationsSubscriptions ?
      sharedLocationsSubscriptions.map(
        s => s.location
      ).filter(
        s => s.type !== LocationType.Individual
      ) :
      undefined,
    [sharedLocationsSubscriptions],
  );

  return [sharedLocations, refetchSharedLocationSubscriptions];
};

export default useSubscribedLocations;

import { ThemeUIStyleObject } from 'theme-ui';
import Theme from 'Common/themePeazy.json';

const styles: Record<string, ThemeUIStyleObject> = {
  accountInfoWrapper: {
    alignItems: 'center',
    columnGap: '16px',
    ':hover': { cursor: 'pointer' },
    position: 'relative',
  },
  accountInfoWrapperV2: {
    alignItems: 'center',
    columnGap: '16px',
    ':hover': { cursor: 'pointer' },
    position: 'relative',
    ':before': {
      bottom: '-100%',
      position: 'absolute',
      display: 'block',
      content: '""',
      width: '100%',
      height: '2rem'
    },
  },
  floatingContainer: {
    top: 'calc(100% + 1rem)',
    position: 'absolute',
    borderRadius: '8px',
    width: '100%',
    opacity: 1,
    ':hover': { opacity: 1, transition: 'opacity 1s ease-in-out' },
  },
  accountInfoInnerContainer: {
    textAlign: 'left',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0px 1px 20px rgba(0, 0, 0, 0.12)',
    minWidth: '245px',
    position: 'relative',
    '> :first-of-type': {
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
    },
    '> :last-of-type': {
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    },
  },
  accountItems: {
    justifyContent: 'start',
    alignItems: 'center',
    width: ['auto', 'auto', '100%'],
    fontSize: '1.125rem',
    color: 'primary',
    p: '0.7rem',
  },
  accountItemsV2: {
    backgroundColor: Theme.colors.newspaper,
    justifyContent: 'start',
    alignItems: 'center',
    width: ['auto', 'auto', '100%'],
    fontSize: '1rem',
    color: 'Black',
    p: '0.7rem',
    '&:hover': {
      fontWeight: 'bold',
    }
  },
  currentLocationTextContainer: {
    fontSize: '1rem',
    alignItems: 'center',
    color: 'Black',
  },
};

export default styles;

import React, { useState, useEffect, useReducer } from 'react';
import FilesContext from '../FilesContext';
import {
  V2Gateway_GetFilesLazyQueryHookResult,
  useV2Gateway_GetFilesLazyQuery,
} from 'Generated/graphql';

type dispatchAction = Partial<Omit<FilesContext, 'dispatch'>>

export type Files = NonNullable<
  V2Gateway_GetFilesLazyQueryHookResult[1]['data']
>['getFilesByExternalUid']

export type FilesContext = {
  readonly dispatch: React.Dispatch<dispatchAction>;
  getFilesQuery: V2Gateway_GetFilesLazyQueryHookResult[1];
  files: Files | null;
  fetchMods: () => void;
}

type FilesContextProviderProps = {
  externalUids: string[];
  children?: React.ReactNode;
}

const FilesContextProvider: React.FC<FilesContextProviderProps> = ({
  children,
  externalUids,
}) => {
  const [_state, _setState] = useState(1);
  const reducer = (
    state: FilesContext,
    action: dispatchAction,
  ): FilesContext => {
    _setState(_state + 1);
    return {
      ...state,
      ...action
    };
  };
  const initialState: Omit<
    FilesContext,
    'dispatch' | 'getFilesQuery' | 'fetchMods'
  > = {
    files: null,
  };
  const [state, dispatch] = useReducer<
    React.Reducer<
      FilesContext, dispatchAction
    >
  >(reducer, initialState as FilesContext);
  const [getFiles, getFilesQuery] = useV2Gateway_GetFilesLazyQuery({
    fetchPolicy: 'network-only',
  });

  const fetchMods = (): void => {
    if (externalUids?.length) {
      getFiles({
        variables: {
          externalUids,
        },
      });
    }
  };

  useEffect(() => {
    fetchMods();
  }, [externalUids]);

  useEffect(() => {
    const { data } = getFilesQuery;
    if (data?.getFilesByExternalUid) {
      const files = [...data.getFilesByExternalUid].sort(
        (a, b) => (a.index || 0) - (b.index || 0)
      );
      dispatch({
        files,
      });
    }
  }, [getFilesQuery.data]);

  return (
    <FilesContext.Provider value={{
      ...state,
      getFilesQuery,
      fetchMods,
      dispatch,
    }}>
      {children}
    </FilesContext.Provider>
  );
};

export default FilesContextProvider;


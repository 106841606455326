import React, { CSSProperties } from 'react';
import { formatServiceTypeSingular, ServiceType } from '@appbookpeazy/common-js';
import { Select } from 'antd';
import {
  Box,
  Flex,
  Text,
  Grid,
  Link,
} from 'theme-ui';
import ArrowLeft from 'Images/svg/arrow-left.turbopack-temp-svg';
import ServiceTypeListItem from './ListItem';
import { SUPPORT_EMAIL } from 'Copy';

type ServiceTypeSelectProps = {
  onSelect?: (types: ServiceType[]) => void;
  onClear?: () => void;
  style: CSSProperties;
  value?: ServiceType[];
  multi?: boolean;
  filter?: (t: ServiceType[]) => ServiceType[];
  presentation?: 'select' | 'card'
  showSupportGridItem?: boolean;
}

export const sortTypes = (arr: ServiceType[]) => {
  const sortOrder = [
    ServiceType.DRY_CLEAN,
    ServiceType.OIL_CHANGE,
    ServiceType.MASSAGE,
    ServiceType.ESTHETICIAN,
    ServiceType.HAIRCUT,
    ServiceType.CAR_WASH,
    ServiceType.DIETITIAN,
    ServiceType.WELLNESS,
    ServiceType.ERGONOMICS,
    ServiceType.PHYSIOTHERAPY,
    ServiceType.FITNESS_YOGA,
    ServiceType.CHEF_SERVICES,
    ServiceType.WINE_TASTING,
    ServiceType.PET_SERVICES,
    ServiceType.PERSONAL_SHOPPING
  ];
  return sortOrder.filter(type => arr.includes(type));
};

const stypes = sortTypes(Object.values(ServiceType));

const Cards = React.memo<{
  onSelect: (type: ServiceType) => void;
  selected: ServiceType[];
  types: ServiceType[];
    }>(({
      onSelect,
      selected,
      types,
    }) => (
      <>
        {types.map<React.ReactNode>(
          (s) => {
            return (
              <ServiceTypeListItem
                key={s}
                serviceType={s}
                onSelect={onSelect}
                value={selected}
              />
            );
          }
        )}
      </>
    ));

const ServiceTypeSelect: React.FC<ServiceTypeSelectProps> = ({
  onSelect,
  onClear,
  style,
  value: selected = [],
  multi,
  filter,
  presentation = 'select',
  showSupportGridItem,
}) => {
  const types = filter ? filter(stypes) : stypes;

  const Options = React.memo(() => (
    <>
      {types.map<React.ReactNode>(
        ([, s]) => (
          <Select.Option key={s} value={s}>
            {formatServiceTypeSingular(s)}
          </Select.Option>
        )
      )}
    </>
  ));

  return presentation === 'select' ? (
    <Select
      mode={multi ? 'multiple' : undefined}
      style={style}
      showSearch
      value={selected.join(',') as ServiceType}
      onChange={(v: ServiceType) => {
        if (onSelect) {
          return multi ? onSelect([...selected, v]) : onSelect([v]);
        }
      }}
    >
      <Options />
    </Select>
  ) : (
    <Flex
      sx={{
        p: '0',
      }}
    >
      {selected.length ? (
        <Flex
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
            background: '#6558C6',
          }}
        >
          <ArrowLeft
            onClick={() => onClear && onClear()}
            sx={{
              fontSize: '1.5rem',
              position: 'fixed',
              left: '1rem',
              top: '2rem',
              zIndex: '9999'
            }}
          />
        </Flex>
      ) : (
        <>
          <Box sx={{
            p: '0',
            m: '0 auto',
            width: '100%',
            overflowY: 'hidden',
          }}>
            <Box sx={{
              px: ['.75rem', '3rem', '5rem'],
              pt: ['0', '3rem', '5rem'],
              pb: ['0', '1.25', '1.5rem']
            }}
            >
              <Flex
                sx={{
                  flexDirection: 'column',
                  alignItems: ['start', 'center', 'center'],
                  pb: ['2rem', '3rem', '5rem']
                }}
              >
                <Text
                  variant='header1'
                  sx={{ mb: '.5rem', mt: '1rem' }}
                >
                  Book a Service
                </Text>
                <Text variant='body1'>
                  Browse services from providers that come to you
                  {' '}and request a booking.
                </Text>
              </Flex>
              <Grid sx={{
                maxWidth: '1060px',
                mx: 'auto',
                justifyContent: 'center',
                columnGap: ['.75rem', '27px'],
                rowGap: ['.75rem', '21px'],
                gridTemplateColumns: [
                  'repeat(2, minmax(0, 1fr))',
                  'repeat(2, minmax(0, 1fr))',
                  'repeat(3, minmax(0, 1fr))',
                ],
              }}>
                <Cards
                  selected={selected}
                  onSelect={(v: ServiceType) => {
                    if (onSelect) {
                      return multi ?
                        onSelect([...selected, v]) :
                        onSelect([v]);
                    }
                  }}
                  types={types}
                />
                {showSupportGridItem && (
                  <Flex
                    sx={{
                      justifyContent: 'center',
                      flexDirection: 'column',
                      pl: '1.5rem'
                    }}
                  >
                    <Text
                      variant="header1"
                      sx={{
                        maxWidth: ['135px', '195px'],
                        fontWeight: '300',
                        fontSize: ['18px', '28px'],
                        lineHeight: ['21px', '33px']
                      }}
                    >
                      How can we help you?
                    </Text>
                    <Flex>
                      <Link
                        href={`mailto:${SUPPORT_EMAIL}`}
                        variant="mainFormButton"
                        sx={{
                          width: 'auto',
                          mt: '1rem',
                          maxWidth: '135px'
                        }}
                      >
                        Contact Us
                      </Link>
                    </Flex>
                  </Flex>
                )}
              </Grid>
            </Box>
          </Box>
        </>
      )}
    </Flex >
  );
};

export default ServiceTypeSelect;
